import register from './labels/register';
import login from './labels/login';
import general from './labels/general';
import footer from './labels/footer';
import sideMenu from './labels/sideMenu';
import users from './labels/users';
import companies from './labels/companies';
import country from './labels/country';
import roles from './labels/roles';
import cities from './labels/cities';
import states from './labels/states';
import threats from './labels/threats';
import assets from './labels/assets';
import vulnerabilities from './labels/vulnerabilities';
import impacts from './labels/impacts';
import parameters from './labels/parameters';
import risks from './labels/risks';
import scopes from './labels/scopes';
import probabilityMeasureTypes from './labels/probabilityMeasureTypes';

export default {
    ...register,
    ...login,
    ...general,
    ...footer,
    ...sideMenu,
    ...users,
    ...roles,
    ...companies,
    ...country,
    ...cities,
    ...states,
    ...threats,
    ...assets,
    ...impacts,
    ...vulnerabilities,
    ...parameters,
    ...risks,
    ...scopes,
    ...probabilityMeasureTypes
};